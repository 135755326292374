@use '@angular/material' as mat;
@use 'assets/scss/phenox-themes.scss' as themes;
@use 'assets/scss/global-classes.scss' as classes;
@use 'assets/scss/phenox-colors.scss' as phenox;
@include mat.core();



$light-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: themes.$phenox_primary,
    tertiary: themes.$phenox_tertiary,
    use-system-variables: false,
  ),
  typography: (
    use-system-variables: false,
  ),
  density: (
    scale: 0,
  )
));
$dark-theme: mat.define-theme((
  color: (
    theme-type: dark,
    primary: themes.$phenox_primary_dark,
    tertiary: themes.$phenox_tertiary_dark,
    use-system-variables: false,
  ),
  typography: (
    use-system-variables: false,
  ),
  density: (
    scale: 0,
  )
));

.fullScreen{
  height: 100%;
}



html, body {

  margin: 0;
  padding: 0;
}

body {
 
@include mat.all-component-themes($light-theme);
@include classes.my-buttonSelected-theme($light-theme, 90);

  &.dark-theme {
@include mat.all-component-themes($dark-theme);
@include classes.my-buttonSelected-theme($dark-theme, 20);

  }
 

}
body {
  min-height: 100vh;
 
  
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
@include classes.body;
