@use '@angular/material' as mat;
@use './phenox-colors.scss' as phenox;


@mixin my-buttonSelected-theme($theme, $hue){
 
// $primary-50: map-get(map-get(map-get(map-get($theme, '_mat-theming-internals-do-not-access'), 'palettes'), 'primary'),50);
$primary: mat.get-theme-color($theme, primary, $hue);

  .buttonSelected {
    --mdc-protected-button-container-color: #{$primary};
    // --mdc-protected-button-container-elevation-shadow:  #{$primary-50};
  }
  
 
}



@mixin body{

  .menu-button {
    min-width: 100px;
    margin-left: 20px;
     }

     .drawer-button {
      max-width:fit-content;
      min-width: 100px;
      margin-top: 20px;
     
    }     

  
.outlet-content-wrapper {
  margin: 10px;
}

  

.multitabs-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;



.content-area {
  flex-grow: 1; /* Content area will take the remaining space */
  padding: 20px; /* Optional padding */
  overflow-y: auto; /* Allow vertical scrolling if content overflows */
  overflow-x: hidden;
}
}
  
  
  .cell-content-ellipsis {
    min-width: 150px;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
  }
  
  .mat-tooltip {
    white-space: pre-wrap;
    word-wrap: break-word;
    max-width: 300px; /* You can change this to suit your needs */
  }



.deleteColor:hover{

  color: phenox.$phenox-red !important;
}
.mat-column-deleteColumn, .mat-column-editColumn {
    width: 80px !important;
  min-width: 80px !important;
  max-width: 80px !important;
  }

}